import * as anchorNav from '@elements/anchor-nav';
import {onFind} from "@elements/init-modules-in-scope";
import {addClass, findAllIn, on} from '@elements/dom-utils';

anchorNav.init({
        renderItem: function (text, anchor) {
            return '<li class="anchor-nav__item list-inline-item"><a class="anchor-nav__link js-smoothscroll__link" data-scroll-offset="260" href="#' + anchor + '"><span aria-hidden="true" class="icon icon-arrow anchor-nav__icon"></span> <span class="anchor-nav__title">' + text + '</span></a></li>';
        },
        smoothScroll: true
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);


