import {onFind} from "@elements/init-modules-in-scope";
import {addClass, closest, findIn, on, removeClass, toggleClass} from "@elements/dom-utils";

export function init() {
    onFind(".js-switch-toggle", function (item) {
        let switchToggle = findIn('.js-switch-toggle__toggle', item);
        let switchPrivate = findIn('.js-switch-toggle__private', item);
        let switchCompany = findIn('.js-switch-toggle__company', item);

        on('click', (evt) => {
            if(evt.target.checked){
                addClass("is-active", switchPrivate);
                removeClass("is-active", switchCompany);
            }else{
                addClass("is-active", switchCompany);
                removeClass("is-active", switchPrivate);
            }
        }, switchToggle);
    });
}